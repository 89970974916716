<template>
    <footer class="bg-gray-900 w-full">
  <div class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
    <!-- Grid -->
    <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
      <div class="col-span-full lg:col-span-1">
        <a class="flex-none text-2xl font-semibold text-white mb-12" href="#" aria-label="Brand">PHARMA' <span class="text-green-800 text-2xl font-semibold">KILIVRE</span></a>
        <img src="@/assets/images/logo-flat.png" class="flex-none text-xl font-semibold my-3" href="#" aria-label="Brand" width="90px" height="auto"/>
      </div>
      <!-- End Col -->

      <div class="col-span-1">
        <h4 class="font-semibold text-gray-100">
          {{ $t('Infos_general_title') }} 
          </h4>

        <div class="mt-3 grid space-y-3 ">
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">info@pharmakilivre.fr</a></p>
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">(+1) 780-394-3884</a></p>
        </div>
      </div>
      <!-- End Col -->

      <div class="col-span-1 ml-12">
        <h4 class="font-semibold text-gray-100">
          {{ $t('Pres_title') }} 
          </h4>

        <div class="mt-3 grid space-y-3 ">
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">{{ $t('Qui nous sommes') }}  </a></p>
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">{{ $t('Services') }}</a></p>
          <p><a class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">{{ $t('Contact') }}</a></p>
        </div>
      </div>
      <!-- End Col -->

      <div class="col-span-2">
        <h4 class="font-semibold text-gray-100">{{ $t('title_sign_newsletter') }}</h4>

        <form>
          <div class="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-lg p-2 dark:bg-gray-800">
            <div class="w-full">
              <label for="hero-input" class="sr-only">Search</label>
              <input type="text" id="hero-input" name="hero-input" class="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-gree-800 focus:ring-green-800 disabled:opacity-50 disabled:pointer-events-none " :placeholder="$t('placeholder_newsletter')">
            </div>
            <a class="w-full sm:w-auto whitespace-nowrap p-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-green-800 text-white hover:bg-green-800 disabled:opacity-50 disabled:pointer-events-none " href="#">
              {{ $t('btn_soumettre') }}
            </a>
          </div>
          <p class="mt-3 text-sm text-gray-400">
            {{ $t('secure_title') }}
          </p>
        </form>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Grid -->

    <div class="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
      <div class="flex justify-between items-center">
        <p class="text-sm text-gray-400">© 2024 PHARMA'KILIVRE. {{ $t('right_reserved') }}.</p>
      </div>
      <!-- End Col -->

    </div>
  </div>
</footer>
</template>

<script>
   export default {
     name:"FooterView"
   }

</script>