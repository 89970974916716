<template>
   <HeaderView/>

 <!-- Hero -->
<div class="relative overflow-hidden my-8 ">
  <div class="relative w-screen mx-auto">
      <div class="w-full object-cover h-[35rem] sm:h-[40rem] bg-white rounded-xl"></div>
      <div class="absolute inset-0 bg-white opacity-50"></div>

      <div class="absolute inset-0 w-full h-full">
        <div class="flex flex-col justify-center items-center w-full h-full">
              <!-- Title -->
          <div class="max-w-[85rem] text-center mx-auto">
              <h1 class="block font-bold text-green-800 text-6xl sm:text-4xl md:text-4xl lg:text-9xl pt-16">
                   Votre livreur pro
              </h1>

          </div>

          <div class="max-w-[85rem] text-center mx-auto">
            <h1 class="block font-medium text-black text-6xl sm:text-4xl md:text-4xl lg:text-9xl">
              de medicaments à domicile.
              </h1>
          </div>
          <!-- End Title -->

           <div class="flex row">
            <a href="https://play.google.com/store/apps/details?id=com.davekabiyesis.pharmakilivre" target="new"  ><img src="@/assets/images/apple-store-badge_2.png" class="flex-none text-xl font-semibold my-2 w-44 sm:w-44 md:w-86 lg:w-86 pr-2"  aria-label="Brand"/></a>
            <a href="https://apps.apple.com/fr/app/pharmakilivre/id6467868052" target="new"><img src="@/assets/images/apple-store-badge.png" class="flex-none text-xl font-semibold my-2 w-44 sm:w-44 md:w-86 lg:w-86" href="#" aria-label="Brand"/></a>
           </div>

        

            <!-- Scroll down arrow -->
        <div class="mt-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="w-12 h-12 transform rotate-45 animate-bounce">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
          </svg>
        </div>
        <!-- End Scroll down arrow -->
        </div>
      </div>

    </div>
</div>
<!-- End Hero -->



<!-- Features -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto" id="about_us">
  <!-- Grid -->
  <div class="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
    <div class="lg:col-span-7">
      <!-- Grid -->
      <div class="grid grid-cols-12 gap-2 sm:gap-6 items-center lg:-translate-x-10">

        <div class="col-span-3">
          <img class="rounded-xl" src="@/assets/images/step1.webp" alt="Image Description">
        </div>
        <!-- End Col -->

        <div class="col-span-4 rounded-full">
          <img class="rounded-xl" src="@/assets/images/delivered.webp" alt="Image Description">
        </div>
        <!-- End Col -->

      
        <div class="col-span-5">
          <img class="rounded-xl" src="@/assets/images/review3.webp" alt="Image Description">
        </div>
        <!-- End Col -->
      </div>
      <!-- End Grid -->
    </div>
    <!-- End Col -->

    <div class="mt-5 sm:mt-10 lg:mt-0 lg:col-span-5">
      <div class="space-y-6 sm:space-y-8">
        <!-- Title -->
        <div class="space-y-2 md:space-y-4">
          <h2 class="font-bold text-3xl lg:text-4xl text-gray-800 ">
            Faites-vous livrer 24h/24 7j/7 en 30 min
          </h2>
          <p class="text-gray-500">
            La santé étant primordiale pour tous, PHARMA'KILIVRE permet de répondre au risque de rupture de soins. Cette problématique touchant des millions de personnes, la création d’un système de livraison de médicaments à domicile 24h/7j paraissait donc indispensable.
            C'est ainsi que PHARMA'KILIVRE est née en 2023. 
          </p>
        </div>
        <!-- End Title -->

        <!-- List -->
        <ul role="list" class="space-y-2 sm:space-y-4">
          <li class="flex space-x-3">
            <!-- Solid Check -->
            <span class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-green-800 text-white ">
              <svg class="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>
            </span>
            <!-- End Solid Check -->

            <span class="text-sm sm:text-base text-green-800">
              <span class="font-bold">Scanner et soumettre votre ordonnance</span>
            </span>
          </li>

          <li class="flex space-x-3">
            <!-- Solid Check -->
            <span class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-green-800 text-white">
              <svg class="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>
            </span>
            <!-- End Solid Check -->

            <span class="text-sm sm:text-base text-green-800 font-bold">
              Valider votre commande
            </span>
          </li>

          <li class="flex space-x-3">
            <!-- Solid Check -->
            <span class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-green-800 text-white ">
              <svg class="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>
            </span>
            <!-- End Solid Check -->

            <span class="text-sm sm:text-base text-green-800 font-bold">
              Livraison dans les 30 minutes qui suive.
            </span>
          </li>
        </ul>
        <!-- End List -->
      </div>
    </div>
    <!-- End Col -->
  </div>
  <!-- End Grid -->
</div>
<!-- End Features -->




<!-- Card Blog -->
 <div class="bg-gray-100"  id="services">
  <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-12">

      <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight "> <span class="text-green-800">Medicaments & Parapharmacie</span></h1>

      <div class=" h-0.5 flex flex-row  mt-3 mb-12">
            <div class="bg-green-800 w-32 h-0.5"></div>
      </div>


  <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="true">
    <Slide v-for="slide in medicaments" :key="slide">
      <div class=" bg-white w-full h-48 text-green-800 text-2xl text-center font-semibold p-3 flex justify-center items-center rounded-lg drop-shadow-md">
           <div class="flex flex-col">
            <img src="@/assets/images/snake.png" class="py-3 lg:py-5 w-12 h-auto md:w-12 lg:w-12 mx-auto" href="#" aria-label="Brand"/>
            {{ slide }}
           </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>

</div>
 </div>
<!-- End Card Blog -->


<div class="max-w-[85rem] px-4  sm:px-6 lg:px-8 lg:py-14 mx-auto mt-12">
 <div class="flex flex-row">
  <img class="rounded-xl w-46 h-auto" src="@/assets/images/Pharmao_ServiceDelivery_3link-scaled.jpg" alt="Image Description">
 </div>
</div>



<div class="w-full bg-gray-100">
     <!-- Clients -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <!-- Title -->
  <div class="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
    <h2 class="text-2xl font-semibold md:text-4xl md:leading-tight text-gray-800 ">Nos partenaires</h2>
    <h2 class="text-xl font-semibold md:text-xl md:leading-tight text-gray-400 ">Ils nous font confiance et nous soutiennent.</h2>

  </div>
  <!-- End Title -->

  <!-- Grid -->
  <div class="grid grid-cols-2 sm:grid-cols-3  md:grid-cols-5 gap-3 lg:gap-6">
    <div class="p-4 md:p-7 bg-white rounded-lg flex flex-col justify-center items-center">
      <img src="@/assets/images/giphar_.png" class="py-3 lg:py-5 w-22 h-auto md:w-20 lg:w-56 mx-auto" href="#" aria-label="Brand"/>
    </div>

    <div class="p-4 md:p-7 bg-white rounded-lg flex flex-col justify-center items-center">
      <img src="@/assets/images/Escale-Sante.webp" class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-56 mx-auto" href="#" aria-label="Brand"/>
    </div>

    <div class="p-4 md:p-7 bg-white rounded-lg flex flex-col justify-center items-center">
      <img src="@/assets/images/groupama-logo.webp" class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-56 mx-auto" href="#" aria-label="Brand"/>
    </div>

    <div class="p-4 md:p-7 bg-white rounded-lg flex flex-col justify-center items-center">
      <img src="@/assets/images/BFM_TV_logo.webp" class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-56 mx-auto" href="#" aria-label="Brand"/>
    </div>

    <div class="p-4 md:p-7 bg-white rounded-lg flex flex-col justify-center items-center">
      <img src="@/assets/images/Credit-Agricole-Logo.webp" class="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-56 mx-auto" href="#" aria-label="Brand"/>
    </div>

   
  </div>
  <!-- End Grid -->
</div>
<!-- End Clients -->
</div>


<div class="w-full bg-white" id="contact_us">
  <!-- Contact Us -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div class="max-w-xl mx-auto">
    <div class="text-center">
      <h1 class="text-3xl font-bold text-gray-800 sm:text-4xl ">
        Contactez-nous
      </h1>
      <p class="mt-1 text-gray-600 ">
        N’hésitez pas à nous contacter pour toute question ou renseignement :
      </p>
    </div>
  </div>

  

  <div class="mt-12 grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-4 lg:gap-8">
    <!-- Icon Block -->
    <a class="group flex flex-col h-full text-center rounded-lg hover:bg-gray-100 p-4 sm:p-6" href="#">
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="w-9 h-9 mx-auto">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
      </svg>

      <div class="mt-5">
        <h3 class="text-lg font-semibold text-green-800 ">Base de connaissance</h3>
        <p class="mt-1 text-gray-500">Rendez-nous visite à notre siège.</p>
        
      </div>
    </a>
    <!-- End Icon Block -->

    <!-- Icon Block -->
    <a class="group flex flex-col h-full text-center rounded-lg hover:bg-gray-100 p-4 sm:p-6 " href="#">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="w-9 h-9 mx-auto">
        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
      </svg>
      <div class="mt-5">
        <h3 class="text-lg font-semibold text-green-800 ">+1 780 394 3884</h3>
        <p class="mt-1 text-gray-600">Nous sommes aussi disponible sur whatsapp.</p>
        
      </div>
    </a>
    <!-- End Icon Block -->

    <!-- Icon Block -->
    <a class="group flex flex-col h-full text-center rounded-lg hover:bg-gray-100 p-4 sm:p-6" href="#">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="w-9 h-9 mx-auto">
        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
      </svg>
      <div class="mt-5">
        <h3 class="text-lg font-semibold text-green-800">info@pharmakilivre.fr</h3>
        <p class="mt-1 text-gray-500">Envoyer nous un message.</p>
        
      </div>
    </a>
    <!-- End Icon Block -->
  </div>
</div>
<!-- End Contact Us -->
</div>






<div class="w-full bg-gray-100">
  <!-- FAQ -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <!-- Grid -->
  <div class="grid md:grid-cols-5 gap-10">
    <div class="md:col-span-2">
      <div class="max-w-xs">
        <h2 class="text-2xl font-bold md:text-4xl md:leading-tight ">Frequently<br>asked questions</h2>
        <p class="mt-1 hidden md:block text-gray-600 ">Nous avons des reponses à toutes vos question</p>
      </div>
    </div>
    <!-- End Col -->

    <div class="md:col-span-3">
      <!-- Accordion -->
      <div class="hs-accordion-group divide-y divide-gray-200 ">
        <div class="hs-accordion pb-3 active" id="hs-basic-with-title-and-arrow-stretched-heading-one">
          <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 " aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
            Comment télécharger l'application Pharmakilibre ?
            <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>
          </button>
          <div id="hs-basic-with-title-and-arrow-stretched-collapse-one" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
            <p class="text-gray-600 ">
              Vous pouvez télécharger l'application Pharmakilivre depuis l’ AppStore , GooglePlay Store en recherchant Pharmakilivre tout simplement.
            </p>
          </div>
        </div>

        <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-two">
          <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
            Comment créer un compte Pharmakilivre ?
             <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>
          </button>
          <div id="hs-basic-with-title-and-arrow-stretched-collapse-two" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
            <p class="text-gray-600 dark:text-gray-400">
              Vous devez tout simplement télécharger l’application Pharmakilivre via AppStore ou GooglePlay pour vous inscrire ou encore via notre site internet en cliquant sur “compte”.             
               </p>
          </div>
        </div>

        <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-three">
          <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 " aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
            Quel est le coût de la livraison ?
            <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>
          </button>
          <div id="hs-basic-with-title-and-arrow-stretched-collapse-three" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
            <p class="text-gray-600 dark:text-gray-400">
              En journée, les frais de livraison sont facturés 4,99€ si la pharmacie choisie est dans un rayon de 3km de votre domicile. Chaque kilomètre supplémentaire est facturé 1€, reversé en totalité au livreur. Entre 20h et 7h, des tarifs de nuits sont appliqués, la livraison vous sera facturée 9,60€ dans un rayon de 3km puis 1€ supplémentaire par km.
            </p>
          </div>
        </div>
      </div>
      <!-- End Accordion -->
    </div>
    <!-- End Col -->
  </div>
  <!-- End Grid -->
</div>
<!-- End FAQ -->
</div>






<FooterViewVue/>
</template>

<script>
// @ is an alias to /src
import HeaderView from './templates/HeaderView.vue';
import FooterViewVue from './templates/FooterView.vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel'


export default {
  name: 'AgenceVoyageView',
  components: {
    HeaderView,
    FooterViewVue,
    Carousel,
    Slide,
    Navigation,
   
  },
  data() {
      return {
         arr: [ this.$t('confier_title')] ,
         formData: {
            name: '',
            email: '',
            phone: '',
            destination: '',
            infos: ''
            
          },
           // carousel settings
            settings: {
              itemsToShow: 1,
              snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
              // 700px and up
              700: {
                itemsToShow: 3.5,
                snapAlign: 'center',
              },
              // 1024 and up
              1024: {
                itemsToShow: 5,
                snapAlign: 'start',
              },
            },
            medicaments: ["Dermatologie", "Douleurs articulaire et dentaire", "Douleurs et fièvre", "Gynécologie", "Ophtalmologie", "Soins Denatires", "Santé", "Sexualité et intimité", "Visage", "Vétérinaire"]
        };
  },
  methods: {
    async submitForm() {
      try {
        const response = await fetch('https://canadaininter.com/sendMail.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.formData)
        });

        const data = await response.json();
        if (data.success) {
          alert('Formulaire soumis avec succès !');
        } else {
          alert('Erreur lors de la soumission du formulaire.');
        }
      } catch (error) {
        console.error('Erreur:', error);
        alert('Une erreur s\'est produite.');
      }
    },
    getRandomBackgroundColor() {
      const colors = [
        'bg-red-800',
        'bg-blue-800',
        'bg-green-800',
        'bg-orange-800',
        'bg-black',
        // Ajoutez d'autres classes de couleur Tailwind CSS au besoin
      ];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    }
  }

}
</script>


<style>
/* Animation pour la flèche */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

.animate-bounce {
    animation: bounce 1.5s infinite;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}




</style>