<template>
    <!-- ========== HEADER ========== -->
<header class="flex flex-wrap sm:justify-start sm:flex-col z-50 w-full bg-white  text-sm pb-2 sm:pb-0 ">
 
  <nav class="relative max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 text-3xl" aria-label="Global">
    <div class="flex items-center justify-between">
      <img src="@/assets/images/logo_pr.png" class="flex-none text-xl font-semibold my-2 max-w-48 sm:w-48 md:w-48 lg:w-48" href="#" aria-label="Brand"/>
      <div class="sm:hidden">
        <button type="button" class="hs-collapse-toggle w-9 h-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none " data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
          <svg class="hs-collapse-open:hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" x2="21" y1="6" y2="6"/><line x1="3" x2="21" y1="12" y2="12"/><line x1="3" x2="21" y1="18" y2="18"/></svg>
          <svg class="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
        </button>
      </div>
    </div>
    <div id="navbar-collapse-with-animation" class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
      <div class="flex text-sm flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
        <router-link :to="{'path': ''}" :class="{ 'active-link': $route.name === 'home' }" replace >
           <a class="font-medium sm:py-6 p-2" href="#" aria-current="page">{{ $t('Accueil') }}</a>
        </router-link>
        
          <a class="font-medium hover:text-gray-500 sm:py-6 p-2" href="#about_us">Presentation </a>
       

          <a class="font-medium hover:text-gray-500 sm:py-6 p-2" href="#services">Services </a>

          <a class="font-medium hover:text-gray-500 sm:py-6 p-2" href="#">Marketplace </a>

        <a class="font-medium text-gray-800 hover:text-gray-500 sm:py-6 p-2" href="#contact_us">{{ $t('Contact') }}</a>

        <a class="flex items-center gap-x-2 font-medium text-gray-500 hover:text-blue-600 sm:border-s sm:border-gray-300 sm:my-6 sm:ps-6 dark:border-gray-700 dark:text-gray-400 dark:hover:text-blue-500" href="https://administration.pharmakilivreservice.com/">
          <svg class="flex-shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
          </svg>
          Compte
        </a>

        
      </div>
    </div>

    <div>
      
    </div>
  </nav>
</header>
<!-- ========== END HEADER ========== -->
</template>

<script>
export default {
  name: 'HeaderView',
  
}


</script>

<style>

.active-link {
  background-color: #0D8706;
  font-weight: bold;
  color: white; 
  padding: 5px 5px;

  border-radius: 8px;
}
</style>