import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import "preline/preline";
import VueWriter from "vue-writer";
import i18n from './i18n';
import 'vue3-carousel/dist/carousel.css'


createApp(App)
.use(router)
.use(VueWriter)
.use(i18n)
.mount('#app')
